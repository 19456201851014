import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          <path d="M2694 4435 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
8z m36 -10 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7
15 -15z"/>
<path d="M2134 4316 c-10 -26 4 -48 28 -44 17 2 23 10 23 28 0 18 -6 26 -23
28 -13 2 -25 -3 -28 -12z m46 -16 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0
13 7 20 20 20 13 0 20 -7 20 -20z"/>
<path d="M1540 4256 c0 -20 5 -26 21 -26 15 0 20 5 17 22 -5 34 -38 37 -38 4z
m30 -1 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
-15z"/>
<path d="M3051 4254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2394 4225 c-18 -20 -19 -24 -6 -27 12 -2 9 -11 -16 -39 -26 -30 -41
-38 -84 -43 -68 -9 -224 -51 -285 -77 -45 -19 -48 -19 -80 -2 -35 19 -41 28
-20 36 6 3 -7 6 -30 6 -31 1 -43 -3 -43 -13 0 -8 -17 -28 -37 -46 -34 -29 -35
-30 -5 -10 18 12 40 19 49 16 9 -4 21 1 27 10 12 15 15 15 46 -1 l33 -17 -74
-40 c-79 -42 -235 -157 -283 -207 -16 -17 -33 -28 -38 -25 -4 3 -8 -3 -8 -14
0 -10 -9 -27 -20 -37 -10 -9 -38 -47 -62 -82 l-42 -65 -22 25 c-12 15 -21 33
-20 42 3 20 135 195 147 195 5 0 9 -10 10 -22 1 -22 1 -22 8 2 5 17 13 24 26
22 17 -3 17 -2 5 14 -13 15 -12 19 14 38 55 40 71 57 24 27 -27 -18 -53 -28
-62 -25 -20 8 -49 -15 -42 -32 7 -17 -129 -197 -156 -207 -13 -4 -18 -13 -14
-23 3 -9 6 -17 6 -20 0 -2 6 -1 13 2 7 2 24 -7 37 -21 l23 -25 -38 -82 c-21
-46 -42 -84 -46 -86 -4 -2 -6 -9 -3 -16 3 -8 -2 -40 -11 -72 -34 -125 -27
-376 14 -531 6 -21 5 -35 -3 -43 -8 -9 -8 -11 3 -8 8 2 18 -14 27 -42 18 -58
18 -58 -8 -63 -21 -4 -21 -4 3 -6 22 -1 31 -13 68 -98 24 -54 72 -148 107
-209 35 -62 64 -114 64 -117 0 -3 12 -19 26 -34 31 -34 74 -122 74 -152 0 -22
0 -22 -51 6 -28 15 -54 33 -56 40 -3 7 -12 13 -20 13 -8 0 -11 5 -8 10 5 8 11
8 19 1 7 -6 16 -7 22 -2 5 5 18 12 29 15 11 3 8 4 -8 2 -22 -3 -31 2 -37 18
-5 11 -9 15 -9 9 -2 -25 -17 -12 -61 53 -63 93 -71 91 -10 -2 30 -46 47 -81
41 -87 -18 -18 -267 156 -431 301 l-65 57 64 -75 c279 -330 644 -627 994 -810
228 -119 439 -198 657 -245 58 -13 93 -24 79 -24 -15 -1 -31 -7 -37 -14 -32
-38 -192 -485 -192 -536 0 -7 25 -11 74 -11 l74 0 11 44 c10 41 13 44 53 50
24 4 53 9 64 12 19 5 22 0 30 -53 l9 -58 120 0 120 0 2 144 c2 87 -2 150 -8
157 -10 13 7 32 18 20 4 -3 1 -6 -6 -6 -7 0 -2 -10 11 -22 13 -13 23 -27 23
-33 1 -5 7 -29 14 -52 16 -48 32 -58 25 -16 -3 15 -2 25 1 21 11 -10 13 -42 4
-54 -13 -16 21 -94 40 -94 11 0 12 -3 4 -8 -7 -4 -10 -17 -6 -30 5 -20 12 -22
67 -23 96 -1 233 3 331 10 l90 6 3 63 3 62 -121 0 -120 0 0 35 c0 41 -3 39
113 55 l87 12 -1 42 -2 41 -7 -40 c-7 -40 -7 -40 -9 14 l-1 54 -78 -6 c-43 -3
-87 -9 -97 -13 -16 -6 -18 -5 -7 7 6 7 12 27 12 45 0 32 0 32 63 38 34 4 79 9
100 12 30 5 37 3 37 -11 0 -15 7 -16 48 -11 26 4 55 9 65 12 16 5 17 -6 17
-126 0 -85 4 -129 10 -125 6 4 10 -20 10 -64 0 -80 -17 -97 -23 -23 -3 39 -4
36 -5 -20 -1 -37 2 -69 7 -72 5 -3 35 -3 67 0 l59 6 2 77 c2 42 5 85 7 96 3
11 5 75 5 143 l1 122 34 0 c18 0 47 3 64 6 28 6 30 10 34 55 1 27 2 53 0 57
-2 7 -610 6 -682 0 -66 -6 -48 8 45 35 245 72 417 204 510 392 48 99 65 179
65 321 0 133 -17 242 -54 352 -28 82 -107 265 -112 259 -2 -2 11 -39 27 -83
97 -247 108 -470 33 -627 -110 -230 -376 -347 -788 -347 -187 0 -304 9 -284
22 7 4 37 15 66 24 45 13 65 14 141 4 76 -11 95 -10 144 4 62 18 119 61 144
109 12 24 14 48 9 112 -7 88 -8 85 67 179 18 21 31 52 34 78 4 24 17 56 30 72
24 28 24 34 18 116 l-7 87 33 27 c17 15 61 42 97 59 63 30 102 71 102 106 0
26 -40 98 -79 143 -48 54 -89 125 -108 186 -11 33 -18 117 -23 277 -9 248 -16
288 -72 399 -41 82 -214 257 -347 353 -6 4 43 42 56 43 7 0 10 7 7 15 -7 17
-25 21 -22 4 2 -6 -9 -20 -25 -32 l-27 -21 -38 22 c-77 47 -170 91 -245 117
-67 24 -76 30 -65 44 11 15 10 15 -6 0 -15 -14 -25 -15 -79 -3 -34 7 -100 16
-146 20 -79 7 -84 8 -102 38 -23 38 -24 46 -1 47 13 0 14 2 2 6 -8 3 -19 12
-23 19 -12 20 -29 17 -53 -11z m46 0 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15
0 8 7 15 15 15 8 0 15 -7 15 -15z m-10 -51 c6 17 9 16 29 -13 l23 -31 -44 1
c-24 1 -54 -1 -68 -6 -23 -7 -23 -5 10 29 29 31 36 34 39 19 3 -17 4 -17 11 1z
m79 -86 c11 -10 12 -10 7 -1 -5 10 5 11 41 7 110 -12 182 -25 243 -41 53 -15
65 -22 71 -43 5 -17 7 -19 8 -7 1 14 6 17 24 11 33 -10 158 -73 190 -97 l29
-20 -83 -83 c-75 -75 -86 -83 -117 -80 -31 2 -32 1 -17 -11 15 -10 16 -17 7
-37 -10 -24 -10 -24 19 -9 16 8 29 22 29 31 0 17 157 182 173 182 5 0 36 -21
70 -47 l61 -47 -40 -12 c-93 -27 -159 -74 -210 -151 -72 -109 -100 -217 -139
-545 -46 -386 -85 -477 -244 -565 -157 -88 -351 -240 -454 -356 -103 -117
-150 -193 -177 -289 l-16 -58 -118 55 c-64 29 -123 59 -131 65 -7 6 -15 32
-18 58 -2 26 -8 54 -12 64 -4 10 -1 18 7 21 9 2 8 4 -2 3 -33 -3 -202 276
-273 452 l-26 62 22 1 c20 1 20 2 -3 8 -21 6 -28 19 -44 74 -19 66 -19 67 0
89 l19 23 -21 -18 c-21 -17 -21 -17 -33 40 -20 95 -26 289 -10 310 12 14 12
16 0 8 -12 -7 -13 2 -7 56 3 35 12 87 19 114 11 43 17 51 43 56 l29 7 -27 1
-27 1 26 63 c14 34 30 66 35 71 5 6 23 -4 45 -25 l38 -34 -38 41 -38 41 43 63
c54 81 157 192 171 183 6 -3 7 -1 3 5 -16 27 293 220 334 209 11 -3 17 -1 14
4 -10 17 134 64 256 84 l45 7 -35 -38 c-35 -39 -35 -39 10 3 38 35 53 42 95
46 103 7 121 7 134 -5z m-649 -28 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
5 10 10 10 6 0 10 -4 10 -10z m-312 -223 c2 -10 -3 -17 -12 -17 -18 0 -29 16
-21 31 9 14 29 6 33 -14z m1402 -138 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0
8 5 12 10 9 6 -3 10 -10 10 -16z m509 -134 c19 -30 45 -86 59 -123 25 -64 26
-76 27 -287 0 -217 1 -221 28 -284 15 -36 61 -115 102 -176 41 -61 75 -119 75
-129 0 -35 -22 -57 -99 -96 -123 -61 -146 -101 -120 -205 11 -42 9 -47 -20
-91 -19 -29 -31 -60 -31 -81 0 -26 -11 -45 -51 -89 l-52 -57 5 -87 c9 -150
-11 -168 -176 -157 -200 14 -368 113 -468 278 -101 166 -62 261 148 360 248
116 291 139 330 173 55 47 77 95 76 170 0 72 -21 129 -96 265 -90 163 -111
223 -111 321 0 74 4 92 28 140 34 66 130 166 212 221 l60 41 20 -26 c12 -14
36 -51 54 -81z m-2099 45 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m1696 -2452 c-3 -51 -9 -127 -13 -168 l-6 -75 -28 136
c-15 75 -34 150 -43 167 l-17 32 57 0 57 0 -7 -92z m263 40 c-1 -29 -4 -80 -8
-113 l-6 -60 -42 106 c-24 59 -43 110 -43 113 0 3 23 6 50 6 l50 0 -1 -52z
m-404 13 c-3 -6 3 -14 12 -20 10 -6 13 -11 7 -11 -15 0 -36 25 -27 33 10 10
15 8 8 -2z m675 -11 c-10 -6 -5 -9 18 -8 18 1 36 -3 40 -7 4 -4 -3 -5 -15 -2
-13 3 -23 1 -23 -4 0 -6 -9 -5 -20 1 -21 11 -19 30 3 30 7 0 6 -4 -3 -10z
m230 -42 c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16 13 16 -11z m-644
-61 c4 -4 -2 -7 -14 -7 -11 0 -24 5 -27 10 -7 11 30 8 41 -3z m-308 -44 c6
-16 14 -49 18 -75 l6 -48 -36 0 c-20 0 -36 2 -36 3 0 2 9 30 20 62 23 66 23
68 12 61 -5 -3 -9 1 -9 9 0 24 14 17 25 -12z m-52 -87 c-25 -55 -44 -77 -30
-33 16 49 47 111 51 101 2 -6 -7 -37 -21 -68z m424 44 c0 -5 -5 -10 -11 -10
-5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m5 -29 c3 -5 1 -12 -5 -16 -5
-3 -10 1 -10 9 0 18 6 21 15 7z m-304 -13 c0 -10 2 -10 6 0 4 10 8 10 19 1 20
-16 18 -43 -2 -35 -9 3 -21 6 -28 6 -6 0 -12 9 -12 20 0 21 15 28 17 8z m597
0 c14 -14 16 -42 2 -33 -6 3 -7 -1 -4 -9 4 -9 1 -16 -6 -16 -7 0 -10 -7 -6
-16 5 -13 4 -14 -7 -6 -8 7 -16 11 -18 9 -2 -1 1 3 7 8 7 6 8 18 3 33 -5 14
-5 22 2 22 6 0 7 5 4 10 -9 14 8 13 23 -2z m-401 -14 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m139 -27 c4 -4 -8 -7 -27 -7 -23 0 -30 3 -20 9
15 10 37 9 47 -2z m-142 -24 c10 -25 3 -23 -17 3 -13 18 -14 22 -3 18 8 -3 17
-13 20 -21z m-174 7 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m340 -50 c12 -7 11 -10 -5 -16 -11 -4 -23 -12 -27 -18 -5 -7
-8 -4 -8 7 0 9 5 17 11 17 5 0 7 5 4 10 -8 12 5 13 25 0z m-369 -23 c-10 -9
-11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m67 0 c6 -7 14 -37 17 -67 l7
-55 -30 35 c-24 28 -28 39 -22 61 5 15 6 30 2 33 -3 3 -1 6 5 6 6 0 15 -6 21
-13z m-228 -7 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m541 -32 c-13 -5 -26 -6 -29 -3 -3 3 0 5 6 5 7 0 12 5 12 11 0 5
-5 7 -12 3 -7 -4 -8 -3 -4 4 5 9 12 9 29 1 l21 -12 -23 -9z m303 14 c3 -5 -1
-9 -9 -9 -8 0 -12 4 -9 9 3 4 7 8 9 8 2 0 6 -4 9 -8z m-473 -19 c13 -16 12
-17 -3 -4 -17 13 -22 21 -14 21 2 0 10 -8 17 -17z m-417 -14 c-3 -5 -10 -7
-15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m774 -40 c3 -11 2
-12 -6 0 -7 10 -14 11 -33 1 -23 -11 -23 -10 -4 5 22 17 37 15 43 -6z m102 1
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-70
-10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-50 -4 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z"/>
<path d="M2402 4045 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2730 4020 c-12 -16 -19 -30 -16 -30 2 0 14 14 26 30 12 17 19 30 16
30 -2 0 -14 -13 -26 -30z"/>
<path d="M2550 4020 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M2193 3960 c-23 -22 -39 -41 -35 -44 4 -2 24 16 45 40 47 54 45 55
-10 4z"/>
<path d="M2392 3975 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2689 3963 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M2040 3966 c0 -3 14 -12 30 -21 17 -9 30 -13 30 -11 0 3 -13 12 -30
21 -16 9 -30 13 -30 11z"/>
<path d="M2881 3954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2658 3923 c-14 -13 -20 -14 -32 -4 -12 10 -14 9 -9 -9 4 -15 -3 -31
-23 -52 l-29 -32 29 22 c36 28 44 28 49 0 4 -23 4 -23 6 1 1 16 5 21 16 17 12
-4 13 -2 2 14 -9 16 -9 24 1 40 15 24 12 25 -10 3z m-8 -33 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M2118 3918 c7 -7 12 -15 12 -19 0 -4 -58 -50 -129 -103 -86 -65 -134
-94 -145 -90 -9 3 -16 1 -16 -5 0 -7 -8 -8 -27 -1 l-28 10 25 -19 c23 -18 24
-22 11 -43 -14 -23 -14 -23 8 -4 21 19 22 19 37 0 15 -18 15 -18 5 3 -8 16 -7
22 5 27 8 3 13 10 10 14 -7 12 237 192 259 192 10 0 27 -8 38 -17 20 -18 20
-18 3 9 -10 15 -23 25 -30 22 -8 -3 -16 4 -19 15 -3 12 -11 21 -18 21 -10 0
-10 -3 -1 -12z m-258 -238 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z"/>
<path d="M2891 3914 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2382 3900 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2901 3864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2690 3856 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M2372 3830 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2215 3820 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M2740 3826 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M2911 3814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2529 3803 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M2652 3785 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2790 3796 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M2255 3770 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M2922 3770 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2362 3755 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2474 3758 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M2840 3766 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M2298 3716 c28 -37 28 -53 0 -59 l-23 -4 23 -2 c12 0 22 -4 22 -8 0
-5 -39 -46 -88 -93 -49 -47 -68 -67 -43 -44 25 22 69 60 96 83 l50 43 6 -28 7
-29 1 28 c1 30 11 33 44 16 21 -12 21 -11 5 8 -24 27 -23 32 20 77 l37 39 -40
-31 c-35 -29 -41 -30 -51 -17 -9 12 -13 13 -17 3 -3 -9 -14 -4 -37 17 l-34 30
22 -29z m68 -47 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z"/>
<path d="M2662 3720 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1552 3655 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1957 3673 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M2138 3663 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"/>
<path d="M2672 3650 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1784 3598 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M2859 3603 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M1562 3585 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2420 3606 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M2682 3590 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2460 3576 c0 -2 14 -14 30 -26 17 -12 30 -19 30 -16 0 2 -13 14 -30
26 -16 12 -30 19 -30 16z"/>
<path d="M1749 3553 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M2332 3530 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2809 3533 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M2692 3520 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1572 3510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1714 3508 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M2530 3526 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M1653 3433 c-23 -27 -44 -45 -48 -41 -4 4 -11 22 -15 40 l-8 33 -1
-39 -1 -38 -37 27 -38 28 28 -29 c36 -40 34 -52 -10 -57 l-38 -4 38 -1 c41 -2
46 -14 20 -46 -18 -21 -18 -21 3 -3 28 23 34 22 35 -10 2 -24 2 -25 6 -5 8 39
44 27 108 -38 32 -33 60 -58 62 -56 2 2 -26 32 -63 68 -40 39 -64 70 -60 77 4
6 28 12 54 13 l47 1 -49 6 c-27 3 -51 11 -53 17 -2 6 13 32 32 57 46 61 40 60
-12 0z m-35 -76 c4 -21 -25 -34 -40 -19 -8 8 -8 16 2 27 16 19 34 15 38 -8z"/>
<path d="M2322 3455 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2600 3476 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M2701 3453 c-1 -38 -7 -40 -41 -16 l-25 16 24 -26 c18 -20 21 -28 10
-31 -40 -13 -42 -14 -19 -15 39 -1 49 -17 38 -58 -5 -19 -7 -37 -5 -40 3 -2 8
10 12 27 4 17 18 38 31 46 17 12 24 26 24 48 0 17 7 43 16 56 13 21 11 20 -11
-4 -27 -29 -39 -27 -48 9 -3 13 -5 9 -6 -12z m39 -59 c0 -19 -28 -30 -42 -16
-8 8 -8 15 1 26 14 17 41 10 41 -10z"/>
<path d="M2452 3456 c7 -8 15 -12 17 -11 5 6 -10 25 -20 25 -5 0 -4 -6 3 -14z"/>
<path d="M2005 3423 c19 -40 19 -43 -1 -43 -10 0 -14 -5 -10 -15 4 -10 -11
-27 -44 -51 -70 -50 -62 -53 10 -4 59 40 62 41 90 26 28 -14 29 -13 20 5 -10
17 -7 19 27 20 27 1 31 3 13 6 -41 8 -46 20 -19 50 l24 28 -26 -22 c-33 -30
-53 -29 -79 5 l-21 27 16 -32z m45 -54 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16
0 8 5 12 10 9 6 -3 10 -10 10 -16z"/>
<path d="M2310 3380 c-4 -19 -13 -26 -40 -31 -30 -5 -31 -7 -8 -8 15 0 29 -5
32 -10 3 -4 -8 -55 -24 -112 -17 -57 -30 -106 -30 -108 0 -2 -7 0 -16 3 -8 3
-12 2 -9 -4 3 -6 -4 -11 -17 -11 -18 -1 -19 -2 -5 -6 11 -3 16 -11 13 -19 -5
-13 12 -19 38 -15 4 1 16 -4 25 -11 13 -10 14 -10 4 2 -7 8 -13 18 -13 22 0 4
79 8 176 8 l175 0 -5 -22 c-3 -13 1 -9 9 8 7 17 17 44 20 60 l6 29 -13 -28
-13 -29 -30 23 -30 23 24 -27 24 -27 -176 0 c-192 0 -186 -2 -168 56 6 16 19
62 31 102 11 39 25 72 30 72 4 0 34 -21 67 -46 l58 -46 -60 55 -60 55 30 5 30
4 -32 2 c-27 1 -32 5 -35 29 l-4 27 -4 -25z m10 -40 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-73 -266 c-9 -9 -28 6 -21
18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z"/>
<path d="M2568 3373 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1833 3363 c26 -2 67 -2 90 0 23 2 2 3 -48 3 -49 0 -68 -1 -42 -3z"/>
<path d="M2493 3363 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2168 3353 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2418 3353 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1474 3248 l-29 -33 33 29 c17 17 32 31 32 33 0 8 -8 1 -36 -29z"/>
<path d="M1829 3231 c-33 -22 -54 -42 -51 -50 3 -8 -1 -12 -9 -8 -8 2 -61 -42
-130 -109 -82 -81 -123 -114 -140 -114 -18 0 -21 -2 -10 -9 13 -8 13 -12 1
-28 -14 -17 -14 -17 6 -1 15 13 23 15 32 6 8 -8 10 -6 6 9 -4 15 21 44 106
126 61 59 116 107 122 107 6 0 21 -8 32 -17 12 -10 15 -11 9 -3 -18 19 -16 30
5 31 14 0 14 2 -3 8 -17 7 -13 12 33 45 28 21 52 40 52 42 0 6 0 6 -61 -35z"/>
<path d="M1571 3244 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2450 3227 c0 -3 23 -24 50 -47 28 -23 50 -40 50 -37 0 3 -22 24 -50
47 -27 23 -50 40 -50 37z"/>
<path d="M1394 3178 l-29 -33 33 29 c17 17 32 31 32 33 0 8 -8 1 -36 -29z"/>
<path d="M1562 3190 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1838 3163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1552 3140 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1888 3153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1938 3143 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1988 3133 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2038 3123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1830 3090 c12 -16 24 -30 26 -30 3 0 -4 14 -16 30 -12 17 -24 30
-26 30 -3 0 4 -13 16 -30z"/>
<path d="M2088 3113 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1542 3090 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2138 3103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1531 3034 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1880 3020 c12 -16 24 -30 26 -30 3 0 -4 14 -16 30 -12 17 -24 30
-26 30 -3 0 4 -13 16 -30z"/>
<path d="M2221 3024 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2300 3005 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25
-28 25 -3 0 5 -11 18 -25z"/>
<path d="M1521 2984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2211 2974 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1348 2973 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1923 2960 c9 -13 17 -28 17 -32 0 -5 -24 -9 -52 -10 l-53 -1 45 -4
c25 -3 49 -8 54 -13 14 -14 -192 -220 -220 -220 -16 0 -35 20 -74 80 -29 44
-55 80 -57 80 -5 0 6 -18 64 -105 43 -65 42 -80 -4 -93 -34 -10 -35 -11 -8 -9
40 2 55 -9 56 -41 2 -24 2 -25 6 -4 7 38 26 21 73 -70 64 -123 72 -123 11 0
-50 100 -55 122 -25 122 20 0 18 18 -3 22 -25 6 182 218 212 218 19 0 88 -69
144 -146 28 -38 20 -63 -23 -65 l-31 -2 30 -4 c38 -6 56 -30 44 -61 -14 -36
-10 -51 5 -17 10 21 15 26 19 15 3 -8 8 -22 11 -30 3 -8 5 -1 4 15 -3 45 28
70 132 106 50 17 89 33 87 35 -1 2 -43 -10 -91 -25 -85 -27 -89 -28 -106 -11
-10 10 -20 26 -23 37 -4 16 -5 16 -6 1 0 -10 -5 -18 -10 -18 -7 0 -81 80 -141
155 -15 18 -32 29 -42 27 -11 -2 -18 3 -18 12 0 19 20 32 29 18 4 -6 18 0 36
15 l30 25 -30 -15 c-34 -16 -50 -13 -84 17 l-24 21 16 -25z m254 -286 c8 -21
-13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z m-457 -24 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M1388 2963 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2380 2910 c29 -33 54 -60 57 -60 3 0 -18 27 -47 60 -29 33 -54 60
-57 60 -3 0 18 -27 47 -60z"/>
<path d="M2556 2924 c-9 -26 -14 -49 -12 -51 2 -2 11 17 20 43 9 26 14 49 12
51 -2 2 -11 -17 -20 -43z"/>
<path d="M1438 2953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1573 2933 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2201 2924 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1713 2923 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2690 2910 c0 -13 7 -20 20 -20 14 0 20 6 19 23 -1 16 -2 18 -6 5 -2
-10 -9 -18 -15 -18 -5 0 -8 7 -4 15 3 8 1 15 -4 15 -6 0 -10 -9 -10 -20z"/>
<path d="M1454 2868 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M2191 2874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2521 2830 c-12 -36 -26 -38 -60 -7 -22 19 -23 19 -8 1 24 -28 21
-59 -5 -72 -21 -11 -21 -12 -3 -11 36 2 37 -1 14 -38 l-22 -38 29 32 c16 17
38 33 49 35 18 3 20 11 20 66 0 34 -1 62 -2 62 0 0 -6 -13 -12 -30z m6 -56 c3
-8 1 -20 -6 -27 -14 -14 -47 15 -37 32 10 16 36 13 43 -5z"/>
<path d="M1414 2818 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M2181 2824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2172 2770 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1848 2653 c29 -2 74 -2 100 0 26 2 2 3 -53 3 -55 0 -76 -1 -47 -3z"/>
<path d="M1548 2623 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1478 2613 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2355 2544 c-9 -15 -12 -23 -6 -20 11 7 35 46 28 46 -3 0 -12 -12
-22 -26z"/>
<path d="M1681 2544 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2096 2504 c-9 -26 -14 -49 -12 -51 2 -2 11 17 20 43 9 26 14 49 12
51 -2 2 -11 -17 -20 -43z"/>
<path d="M2171 2524 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1671 2504 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2280 2430 c-52 -79 -63 -86 -73 -45 -3 11 -5 2 -6 -19 -1 -29 -9
-48 -28 -69 l-28 -29 39 26 c21 14 43 26 47 26 5 0 9 8 9 18 0 9 15 40 33 67
58 87 69 105 64 105 -2 0 -28 -36 -57 -80z"/>
<path d="M1935 2490 c-8 -13 3 -30 18 -29 9 0 9 2 0 6 -7 2 -13 9 -13 15 0 6
7 7 16 4 8 -3 12 -2 9 4 -8 13 -22 13 -30 0z"/>
<path d="M2181 2474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1661 2464 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2192 2430 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1888 2295 c47 -94 58 -125 51 -142 -5 -14 -17 -23 -31 -24 -17 -1
-14 -4 12 -11 68 -19 84 -16 95 19 6 18 27 47 46 65 19 18 26 26 15 17 -12 -9
-34 -25 -50 -35 l-28 -18 6 35 7 34 -16 -28 c-9 -16 -21 -27 -28 -24 -6 2 -36
51 -67 110 -78 147 -85 148 -12 2z m102 -155 c0 -21 -15 -27 -25 -10 -7 12 2
30 16 30 5 0 9 -9 9 -20z"/>
<path d="M1641 2374 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1631 2334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1621 2294 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1611 2254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2119 2253 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M1858 2113 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1808 2103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1758 2093 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2528 4203 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2608 4193 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2683 4183 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2790 4180 c-45 -7 -45 -7 -8 -8 25 -2 42 -9 51 -23 11 -14 16 -17
19 -7 4 10 15 8 51 -9 26 -13 47 -20 47 -17 0 2 -27 18 -60 33 -33 16 -57 31
-53 35 3 3 4 5 2 4 -2 0 -24 -4 -49 -8z"/>
<path d="M2258 4173 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2108 4133 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2852 4100 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1173 3994 c-3 -8 2 -23 11 -32 15 -15 17 -15 32 0 21 21 11 48 -16
48 -11 0 -23 -7 -27 -16z m45 -11 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18
-2 12 3 17 18 17 15 0 20 -5 18 -17z"/>
<path d="M1703 3959 c-24 -17 -43 -32 -43 -35 0 -3 20 9 45 26 25 17 45 33 45
35 0 7 -2 6 -47 -26z"/>
<path d="M1159 3712 c9 -7 10 -11 2 -15 -7 -4 -8 -5 0 -3 15 2 13 20 -3 24
-10 3 -10 2 1 -6z"/>
<path d="M1307 3515 c-24 -50 -20 -58 4 -12 11 22 19 41 17 43 -3 2 -12 -12
-21 -31z"/>
<path d="M1267 3424 c-10 -19 -15 -40 -12 -45 4 -5 1 -9 -5 -9 -6 0 -8 -5 -5
-10 9 -15 27 0 21 17 -3 8 1 28 10 45 8 16 14 32 12 34 -3 2 -12 -12 -21 -32z"/>
<path d="M1000 3340 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z m30 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z"/>
<path d="M1242 3305 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1232 3235 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1222 3165 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1212 3095 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1242 3048 c-16 -17 -25 -20 -30 -11 -6 9 -11 7 -20 -7 -10 -16 -9
-18 5 -13 11 4 14 2 11 -8 -3 -8 -12 -13 -19 -11 -11 3 -12 1 -1 -9 8 -8 13
-49 14 -113 l2 -101 5 110 6 110 35 -2 35 -2 -34 10 -34 10 29 30 c32 34 29
40 -4 7z"/>
<path d="M944 2865 c-4 -9 0 -23 9 -32 21 -21 49 -9 45 19 -4 28 -45 38 -54
13z m46 -16 c0 -25 -33 -22 -38 4 -2 12 3 17 17 17 15 0 21 -6 21 -21z"/>
<path d="M1294 2668 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M1209 2625 c-3 -16 -8 -37 -11 -45 -3 -8 -3 -12 -1 -7 9 14 35 7 29
-8 -3 -9 10 -37 30 -65 47 -66 54 -64 10 2 -19 28 -32 56 -28 62 3 6 7 17 8
24 2 8 11 23 21 35 10 12 3 9 -14 -7 l-32 -28 -3 33 -3 34 -6 -30z"/>
<path d="M1278 2583 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1390 2305 c28 -41 53 -75 56 -75 2 0 -18 34 -46 75 -28 41 -53 75
-56 75 -2 0 18 -34 46 -75z"/>
<path d="M1581 2124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1294 1269 c-17 -5 -50 -29 -73 -54 l-41 -45 0 50 0 50 -65 0 c-79 0
-74 15 -77 -217 -3 -157 -5 -174 -24 -200 -29 -38 -55 -36 -73 5 -15 32 -16
33 -75 30 l-61 -3 3 -45 c6 -92 66 -145 163 -144 65 1 116 27 152 78 12 17 30
32 41 34 15 3 18 -1 14 -23 -4 -19 1 -31 24 -50 83 -70 204 -45 268 54 15 22
16 21 15 -35 l0 -59 82 1 c109 1 160 22 217 85 53 59 85 139 93 231 10 120
-25 205 -101 239 -51 23 -94 24 -191 2 l-75 -16 0 -35 0 -35 -23 34 c-25 38
-98 79 -137 78 -14 0 -39 -5 -56 -10z m110 -30 c-9 -15 -34 -10 -28 5 3 8 12
12 20 9 8 -3 11 -9 8 -14z m330 -4 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8
4 15 10 15 5 0 7 -7 4 -15z m-350 -107 c5 -21 13 -38 18 -38 4 0 8 -5 8 -11 0
-5 -4 -7 -10 -4 -6 3 -10 -5 -10 -19 0 -14 5 -28 11 -32 8 -4 7 -9 -2 -15 -11
-7 -10 -9 4 -9 9 0 17 -8 17 -18 0 -9 8 -22 19 -29 13 -8 17 -19 13 -40 -2
-15 -2 -25 2 -22 4 4 9 19 12 35 2 16 6 34 9 41 2 6 -1 14 -8 16 -7 2 -9 8 -5
12 16 17 27 -10 26 -62 -1 -45 -5 -57 -20 -61 -10 -2 -18 -8 -18 -13 0 -5 -7
-9 -15 -9 -8 0 -15 5 -15 10 0 6 4 10 9 10 5 0 8 8 6 18 -1 9 2 25 6 34 7 14
3 21 -20 32 -26 14 -30 14 -35 0 -9 -23 -7 -44 3 -44 5 0 7 10 3 22 -3 13 -1
19 5 15 6 -4 13 -16 16 -27 4 -16 0 -20 -19 -20 -16 0 -24 -6 -24 -18 0 -26
-29 -62 -50 -62 -49 0 -64 97 -36 230 8 36 12 67 11 68 -2 2 6 15 18 28 31 36
60 28 71 -18z m431 12 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10
8 0 12 -4 9 -10z m-705 -4 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m606 -4 c16 -10 42 -74 36 -90 -2 -7 0 -12 5 -11 23 4
25 -3 5 -17 -12 -8 -22 -24 -22 -35 0 -30 -41 -109 -64 -124 -12 -7 -31 -16
-42 -19 -18 -5 -23 -2 -27 21 -3 15 -1 33 3 38 5 6 10 57 12 113 1 57 5 107 8
111 12 18 66 27 86 13z m-189 -34 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m291 -9 c-3 -8 -7 -3 -11 10 -4 17 -3 21 5 13 5 -5 8 -16 6 -23z
m-685 4 c4 -3 2 -12 -3 -19 -6 -6 -10 -16 -9 -20 4 -23 -3 -34 -18 -33 -10 0
-13 3 -7 5 7 3 10 14 7 24 -3 13 1 22 12 26 13 5 14 9 3 15 -7 5 -8 9 -2 9 6
0 14 -3 17 -7z m654 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m47 -40 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m-36 -74 c7
-12 17 -18 22 -15 16 10 11 -11 -5 -25 -13 -10 -15 -9 -15 9 0 11 -4 21 -9 21
-5 0 -11 7 -15 15 -8 22 7 18 22 -5z m-668 -10 c0 -5 -7 -7 -15 -4 -8 4 -12
10 -9 15 6 11 24 2 24 -11z m657 -45 c0 -17 -13 -20 -21 -6 -4 5 0 12 6 14 7
3 13 6 14 6 0 1 1 -6 1 -14z m-690 -87 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m83 18 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z m310 0 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m-443 -32 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m433 2 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m-420 -26 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m358 -9 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1
12 9 9 7 -2 15 -10 17 -17z m-148 -1 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10
0 6 7 10 15 10 8 0 15 -4 15 -10z m264 -24 c-6 -15 -24 -23 -24 -11 0 8 24 34
28 30 1 -1 0 -10 -4 -19z m-181 -16 c-3 -11 -7 -20 -9 -20 -2 0 -4 9 -4 20 0
11 4 20 9 20 5 0 7 -9 4 -20z"/>
<path d="M2068 1263 c-20 -2 -41 -10 -47 -16 -31 -38 -191 -486 -191 -536 0
-7 25 -11 74 -11 l74 0 11 44 c10 41 13 44 53 50 24 4 53 9 64 12 19 5 22 0
30 -53 l9 -58 68 -3 c50 -2 69 1 73 11 5 13 -41 281 -72 423 -17 77 -46 134
-68 134 -8 0 -20 1 -28 3 -7 2 -30 2 -50 0z m67 -52 c-3 -6 3 -14 12 -20 10
-6 13 -11 7 -11 -15 0 -36 25 -27 33 10 10 15 8 8 -2z m-47 -158 c6 -16 14
-49 18 -75 l6 -48 -36 0 c-20 0 -36 2 -36 3 0 2 9 30 20 62 23 66 23 68 12 61
-5 -3 -9 1 -9 9 0 24 14 17 25 -12z m-52 -87 c-25 -55 -44 -77 -30 -33 16 49
47 111 51 101 2 -6 -7 -37 -21 -68z m125 2 c0 -10 2 -10 6 0 4 10 8 10 19 1
20 -16 18 -43 -2 -35 -9 3 -21 6 -28 6 -6 0 -12 9 -12 20 0 21 15 28 17 8z
m19 -58 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-29 -73 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m66 1
c13 -16 33 -118 23 -118 -9 0 -55 64 -52 73 1 4 3 19 4 32 3 28 11 31 25 13z
m-227 -8 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-46 -51 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7
-10 3 -15z"/>
<path d="M2408 1263 l-147 -4 -5 -27 c-3 -15 -5 -49 -5 -76 l0 -49 44 6 c25 4
55 9 68 12 l23 6 -3 -138 c-2 -76 0 -132 3 -125 13 28 19 8 16 -55 -3 -46 -9
-71 -20 -79 -9 -7 -13 -18 -9 -29 6 -15 15 -16 70 -10 34 4 63 8 64 9 1 0 4
89 8 196 8 250 6 240 55 240 21 0 50 4 65 10 24 9 26 14 23 62 l-3 53 -50 1
c-27 0 -116 -1 -197 -3z m-70 -117 c9 -2 4 -4 -13 -3 -35 2 -52 19 -27 28 10
4 18 1 20 -7 2 -7 11 -15 20 -18z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
